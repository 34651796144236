<template>
  <v-row no-gutters>
    <v-col>
      <div id="tracking-list" :class="{ 'page-fullscreen': pageStore.isFullScreen }">
        <v-card tile :class="$vuetify.breakpoint.smAndDown ? 'rounded-xl' : 'rounded-t-xl'">
          <PageHeader>
            <template #action v-if="userData.role === 'PLSYR'">
              <v-btn class="text-capitalize" :to="{ name: 'collection-add' }" color="success" rounded
                >Çek Tahsilat</v-btn
              >
            </template>
          </PageHeader>
          <v-data-table
            loader-height="2"
            :headers="tableColumns"
            :items="tableItems"
            :loading="tableLoading"
            :height="pageStore.tableHeight"
            :options.sync="tableOptions"
            :server-items-length="tableTotalRec"
            :disable-items-per-page="true"
            :mobile-breakpoint="100"
            :items-per-page="20"
            fixed-header
            hide-default-footer
          >
            <!-- Load More -->
            <template v-slot:body.append v-if="loadMoreLoading">
              <tr>
                <td :colspan="tableColumns.length">
                  <div class="text-center" v-intersect.quiet="loadMore">
                    <v-progress-circular
                      v-if="tableItems.length > 0"
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                  </div>
                </td>
              </tr>
            </template>

            <!-- Arama Alanı -->
            <template v-slot:body.prepend>
              <tr class="data-table-body-search">
                <!-- Durum -->
                <td class="pa-0">
                  <v-select
                    v-model="tableSearchStatus"
                    :prepend-inner-icon="icons.mdiMagnify"
                    persistent-placeholder
                    :items="pinia.statusList"
                    placeholder="Ara"
                    filled
                    clearable
                    class="rounded-0 elevation-0"
                    hide-details
                    attach
                    :menu-props="{ bottom: true, offsetY: true }"
                  />
                </td>

                <!-- Plasiyer -->
                <td class="pa-0">
                  <v-autocomplete
                    v-model="tableSearchPlasiyer"
                    :items="plasiyerItems"
                    :prepend-inner-icon="icons.mdiMagnify"
                    persistent-placeholder
                    clearable
                    placeholder="Ara"
                    filled
                    class="rounded-0 elevation-0"
                    hide-details
                  />
                </td>

                <!-- Fatura -->
                <!-- <td class="pa-0">
                  <v-text-field
                    v-model="tableSearchInvoce"
                    :prepend-inner-icon="icons.mdiMagnify"
                    persistent-placeholder
                    clearable
                    placeholder="Ara"
                    filled
                    class="rounded-0 elevation-0"
                    hide-details
                  />
                </td> -->

                <!-- Açıklama -->
                <td class="pa-0">
                  <v-text-field
                    v-model="tableSearchDescription"
                    :prepend-inner-icon="icons.mdiMagnify"
                    persistent-placeholder
                    clearable
                    placeholder="Ara"
                    filled
                    class="rounded-0 elevation-0"
                    hide-details
                  />
                </td>

                <!-- Çek Tutar -->
                <!-- <td class="pa-0">
                  <v-text-field
                    v-model="tableSearchChecksTotal"
                    :prepend-inner-icon="icons.mdiMagnify"
                    persistent-placeholder
                    clearable
                    placeholder="Ara"
                    filled
                    class="rounded-0 elevation-0"
                    hide-details
                  />
                </td> -->

                <!-- Çek Adet -->
                <!-- <td class="pa-0">
                  <v-text-field
                    v-model="tableSearchChecksPic"
                    :prepend-inner-icon="icons.mdiMagnify"
                    persistent-placeholder
                    clearable
                    placeholder="Ara"
                    filled
                    class="rounded-0 elevation-0"
                    hide-details
                  />
                </td> -->
                <!-- Boş -->
                <td class="pa-0" v-for="(item, i) in tableEmptySearch" :key="i">
                  <v-text-field filled disabled class="rounded-0 elevation-0" hide-details />
                </td>
              </tr>
            </template>

            <!-- Durum -->
            <template #[`item.status`]="{ item }">
              <v-alert class="ma-0" style="padding: 6px 0" :color="pinia.statusList[item.status].type" text outlined>
                <div class="text-center text-caption" style="font-size: 11px !important; letter-spacing: 1.5px">
                  {{ pinia.statusList[item.status].text }}
                </div>
              </v-alert>
            </template>

            <!-- Plasiyer -->
            <template #[`item.plasiyer`]="{ item }">
              <v-chip label color="grey" class="d-flex justify-center text-caption">
                <span class="text-caption text-xl-subtitle-2" style="letter-spacing: 0.5px; color: azure">{{
                  item.plasiyer.uname
                }}</span>
              </v-chip>
            </template>

            <!-- Faturalar -->
            <template #[`item.invoices`]="{ item }">
              <v-chip
                color="secondary"
                label
                class="d-flex justify-center text-caption"
                style="font-size: 11px !important; letter-spacing: 1.5px; color: azure"
              >
                <!-- {{ item.invoices }} -->
              </v-chip>
            </template>

            <!-- Çek Tutar -->
            <template #[`item.checksTotal`]="{ item }">
              <div v-if="item.checksTotal" class="text-caption white--text font-weight-medium">
                {{ item.checksTotal | currency({ symbol: '' }) }}
              </div>
            </template>

            <!-- Çek Adet -->
            <template #[`item.checksPic`]="{ item }">
              <v-chip color="#9391a2" outlined class="d-flex justify-center" label>
                <div class="text-capitalize" style="text-align: center; font-size: 11px !important">
                  {{ item.checksPic }} Adet
                </div>
              </v-chip>
            </template>

            <!-- Kayıt Tarihi -->
            <template #[`item.created_at`]="{ item }">
              <v-tooltip color="chip" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip color="" class="d-flex justify-center" label v-bind="attrs" v-on="on">
                    <div
                      class="text-truncate d-flex align-center"
                      style="
                        width: 130px !important;
                        text-align: center;
                        font-size: 11px !important;
                        letter-spacing: 0.5px;
                        color: azure;
                      "
                    >
                      <v-icon color="" small class="mr-2">
                        {{ icons.mdiCalendarClock }}
                      </v-icon>
                      <div class="mr-1">
                        {{ item.created_at | moment('DD.MM.YYYY ') }}
                      </div>

                      <span> - {{ item.created_at | moment('HH:mm') }}</span>
                    </div>
                  </v-chip>
                </template>
                <div>
                  {{ item.created_at | moment('DD MMMM dddd') }}
                </div>
              </v-tooltip>
            </template>

            <!-- Görüntüle -->
            <template #[`item.actions`]="{ item }">
              <v-btn
                @click="viewCollectionAction(item)"
                depressed
                style="height: 32px !important"
                :color="item.status == 3 ? 'error' : 'chip'"
                block
                class="text-capitalize"
              >
                <span v-if="item.status == 3">Düzenle </span>
                <span v-else>Görüntüle</span>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </div>
      <CollectionView v-if="pinia.viewDialogModel" @tableFetch="tableFetchView" />
    </v-col>
  </v-row>
</template>

<script>
import { ref, watch, onMounted, onUnmounted } from 'vue-demi'

// Pinia
import { usePageStore } from '@/stores/pageHeader'
import { useCollectionStore } from '../store'

// Utils
import { postData, sumArrayProp } from '@utils'
import { useDebounceFn } from '@vueuse/core'
import { useToast } from 'vue-toastification/composition'

// Component
import PageHeader from '@/components/PageHeader/Index.vue'
import CollectionView from './View.vue'

// Icons
import { mdiCheck, mdiCalendarClock, mdiCashMultiple, mdiMagnify, mdiDownload, mdiDelete } from '@mdi/js'

export default {
  components: {
    PageHeader,
    CollectionView,
  },

  setup() {
    const pinia = useCollectionStore()
    const pageStore = usePageStore()
    const toast = useToast()
    const userData = JSON.parse(localStorage.getItem('userData'))

    // Sayfa Özellikleri
    const pageSettings = () => {
      pageStore.pageTitle = 'Tahsilatlar'
      pageStore.pageIcon = mdiCashMultiple
      pageStore.hasFullScreen = true
      pageStore.hasDownload = true
      pageStore.hasDateRangeAll = false
      pageStore.hasDateRange = false
      pageStore.hasActions = true
    }

    // Table
    const tableLoading = ref(true)
    const tableItems = ref([])
    const tableTotalRec = ref(0)
    const tableColumns = ref([
      {
        text: 'Durum',
        value: 'status',
        align: 'left px-4 px-lg-3',
        width: 200,
        sortable: false,
      },
      {
        text: 'Plasiyer',
        value: 'plasiyer',
        width: 150,
        align: 'left pl-0 pr-3 px-lg-3',
        sortable: false,
      },

      // {
      //   text: 'Fatura',
      //   value: 'invoices',
      //   width: 150,
      //   align: 'left pl-0 pr-3 px-lg-3',
      //   sortable: false,
      // },
      {
        text: 'Açıklama',
        value: 'description',
        align: 'left pl-0 pr-3 px-lg-3 width-120-220',
        sortable: false,
      },

      {
        text: 'Tutar (TL)',
        align: 'right pl-0 pr-3 px-lg-3',
        value: 'checksTotal',
        width: '100',
        sortable: false,
      },
      {
        text: 'Çek',
        value: 'checksPic',
        width: 120,
        align: 'left pl-0 pr-3 px-lg-3',
        sortable: false,
      },
      {
        text: 'Kayıt Tarih',
        align: 'center pl-0 pr-3 px-lg-3',
        value: 'created_at',
        width: '130px',
        sortable: false,
      },
      {
        text: '',
        align: 'right px-3',
        value: 'actions',
        width: '80px',
        sortable: false,
      },
    ])

    // Table Load More
    const tablePage = ref(1)
    const loadMoreLoading = ref(false)
    const loadMore = isIntersecting => {
      if (isIntersecting) {
        tablePage.value++
        tableOptions.value.page = tablePage.value
        if (!tableLoading.value) {
          tableFetch()
        }
      }
    }

    // Tablo Arama

    const tableSearchStatus = ref('')
    const tableSearchPlasiyer = ref('')
    const tableSearchInvoce = ref('')
    const tableSearchDescription = ref('')
    const tableSearchChecksTotal = ref('')
    const tableSearchChecksPic = ref('')
    const tableEmptySearch = ref(4)
    const tableOptions = ref({
      sortBy: ['id'],
      sortDesc: [true],
    })

    const debouncedTableSearch = useDebounceFn(() => {
      tableFetch()
    }, 700)

    watch(
      [
        tableSearchStatus,
        tableSearchPlasiyer,
        tableSearchInvoce,
        tableSearchDescription,
        tableSearchChecksTotal,
        tableSearchChecksPic,
      ],
      () => {
        loadMoreLoading.value = false
        tableLoading.value = true
        tablePage.value = 1
        tableOptions.value.page = 1
        tableItems.value = []

        debouncedTableSearch()
      },
    )

    // API İstek
    const tableFetch = () => {
      tableLoading.value = true

      postData({
        method: 'getPlasiyerCollection',
        options: JSON.stringify(tableOptions.value),
        search: JSON.stringify({
          status: tableSearchStatus.value,
          plasiyer: tableSearchPlasiyer.value,
          invoce: tableSearchInvoce.value,
          description: tableSearchDescription.value,
          checks_total: tableSearchChecksTotal.value,
          checks_pic: tableSearchChecksPic.value,
        }),
      })
        .then(response => {
          // API dönüşündeki hatalara bakıyoruz
          if (response.error) {
            throw new Error(response.msg)
          }

          // Toplam kayıt sayısı işleniyor
          tableTotalRec.value = Number(response.response.pagination.totalCount)

          // API'dan gelen veriler işleniyor

          // Responseda bir değer yoksa
          if (response.response.result.length === 0) {
            loadMoreLoading.value = false
            return
          }

          // Responsedan gelen veriler işleniyor
          response.response.result.map(item => {
            tableItems.value.push({
              id: item.id,
              status: item.status,
              status_label: item.status_label,
              plasiyer: item.plasiyer,
              description: item.description,
              checks: item.checks,
              invoices_details: item.invoices_details,
              checksPic: item.checks.length,
              checksTotal: sumArrayProp(item.checks, 'total_price'),
              invoices: item.registered_invoices,
              created_at: item.created_at,
              updated_at: item.updated_at,
              manage_show_approve_button: item.manage_show_approve_button,
              manage_show_cancel_button: item.manage_show_cancel_button,
              inform_msg: item.inform_msg,
              actions: null,
            })
          })

          loadMoreLoading.value = false
          if (tableTotalRec.value >= 20) {
            setTimeout(() => {
              loadMoreLoading.value = true
            }, 300)
          }
          // Responstan gelen verilere göre loadmore değerlendiriliyor
        })
        .catch(e => {
          // API hataya girdi
          toast.error(e.message, {
            position: 'bottom-center',
          })
        })
        .finally(() => {
          // API işlemi bittikten sonra
          tableLoading.value = false
        })
    }

    const tableFetchView = () => {
      pinia.viewDialogModel = false
      loadMoreLoading.value = false
      tableLoading.value = true
      tablePage.value = 1
      tableOptions.value.page = 1
      tableItems.value = []
      debouncedTableSearch()
    }

    const statusValue = ref(null)
    const statusItems = ref([])

    const status = () => {
      postData({
        method: 'getCollectionStatusEnums',
      })
        .then(response => {
          // API dönüşündeki hatalara bakıyoruz
          if (response.error) {
            throw new Error(response.msg)
          }

          response.result.forEach((text, value) => {
            statusItems.value.push({
              text,
              value,
            })
          })
        })
        .catch(e => {
          // API hataya girdi
          toast.error(e.message, {
            position: 'bottom-center',
          })
        })
        .finally(() => {
          // API işlemi bittikten sonra
        })
    }

    const plasiyerItems = ref([])
    const plasiyerList = () => {
      postData({
        method: 'getSaldeptList',
      })
        .then(response => {
          // API dönüşündeki hatalara bakıyoruz
          if (response.error) {
            throw new Error(response.msg)
          }

          response.list.forEach(item => {
            plasiyerItems.value.push({
              text: item.name,
              value: item.saldept,
            })
          })
        })
        .catch(e => {
          // API hataya girdi
          toast.error(e.message, {
            position: 'bottom-center',
          })
        })
        .finally(() => {
          // API işlemi bittikten sonra
        })
    }

    const viewCollectionAction = data => {
      console.log(data)
      pinia.viewDialogData = data
      pinia.viewDialogModel = true
    }

    onMounted(() => {
      pageSettings()
      status()
      if (userData.role !== 'PLSYR') {
        plasiyerList()
      }
      tableFetch()
    })

    onUnmounted(() => {
      pageStore.pageSettingsClear()
    })

    return {
      // Utils
      userData,
      pinia,
      pageStore,

      // Table
      tableFetch,
      tableColumns,
      tableItems,
      tableLoading,
      loadMore,
      loadMoreLoading,
      tableTotalRec,
      tableEmptySearch,
      tableOptions,

      // Table Search
      tableSearchStatus,
      tableSearchPlasiyer,
      tableSearchInvoce,
      tableSearchDescription,
      tableSearchChecksTotal,
      tableSearchChecksPic,

      // Status
      statusItems,
      statusValue,

      // Plasiyer
      plasiyerItems,

      // View
      viewCollectionAction,
      tableFetchView,

      // Icons
      icons: {
        mdiCheck,
        mdiCashMultiple,
        mdiMagnify,
        mdiDownload,
        mdiDelete,
        mdiCalendarClock,
      },
    }
  },
}
</script>
