<template>
  <div>
    <v-dialog
      v-model="pinia.viewDialogModel"
      :persistent="pinia.viewDialogLoading"
      max-width="650"
      class="elevation-0"
      scrollable
      overlay-opacity="0.6"
      attach=""
    >
      <v-card
        rounded="xl"
        flat
        :disabled="pinia.viewDialogLoading"
        :class="{
          'scrollable-card': !isScroll,
          'scrollable-text-card': !isScroll && $vuetify.breakpoint.mdAndDown,
          'scrollable-not-card': !isScroll && arrivedState.bottom,
        }"
      >
        <v-card-title class="grey">
          <h4 class="text-body-1 text-sm-h6 font-weight-bold">Tahsilat Detay</h4>

          <v-spacer />

          <v-btn
            v-if="$vuetify.breakpoint.smAndUp"
            rounded
            small
            depressed
            color="chip"
            class="text-capitalize"
            @click="pinia.viewDialogClose"
          >
            <v-icon left>{{ icons.mdiClose }}</v-icon>
            Kapat
          </v-btn>
          <v-icon v-else small @click="pinia.viewDialogClose">{{ icons.mdiClose }}</v-icon>
        </v-card-title>
        <v-alert type="warning"   rounded="0" class="ma-0" text>
          {{ pinia.viewDialogData.inform_msg }}
        </v-alert>
        <v-divider />
        <v-card-text
          class="pa-0"
          ref="scrollableEl"
          :style="$vuetify.breakpoint.smAndUp ? 'height: 735px' : 'height: 100dvh'"
        >
          <v-list>
            <v-list-item class="px-3 px-md-6" two-line>
              <v-list-item-content>
                <v-list-item-subtitle>ID</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-chip color="chip" label class="d-flex justify-center text-caption">
                  {{ pinia.viewDialogData.id }}
                </v-chip>
              </v-list-item-action>
            </v-list-item>
            <v-divider />

            <v-list-item class="px-3 px-md-6" two-line>
              <v-list-item-content>
                <v-list-item-subtitle>Durum</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-chip
                  :color="pinia.statusList[pinia.viewDialogData.status].type"
                  label
                  class="d-flex justify-center text-caption"
                  style="font-size: 11px !important; letter-spacing: 1.5px; color: azure"
                >
                  {{ pinia.statusList[pinia.viewDialogData.status].text }}
                </v-chip>
              </v-list-item-action>
            </v-list-item>
            <v-divider />

            <v-list-item class="px-3 px-md-6">
              <v-list-item-content>
                <v-list-item-subtitle>Cari Unvan</v-list-item-subtitle>
                <v-list-item-title v-if="!$vuetify.breakpoint.smAndUp">{{
                  pinia.viewDialogData.plasiyer.display_name
                }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action v-if="$vuetify.breakpoint.smAndUp">
                {{ pinia.viewDialogData.plasiyer.display_name }}
              </v-list-item-action>
            </v-list-item>
            <v-divider />

            <v-list-item class="px-3 px-md-6">
              <v-list-item-content>
                <v-list-item-subtitle>Güncelleme Tarihi</v-list-item-subtitle>
                <v-list-item-title v-if="!$vuetify.breakpoint.smAndUp">{{
                  pinia.viewDialogData.updated_at | moment('DD.MM.YYYY - HH:mm')
                }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action v-if="$vuetify.breakpoint.smAndUp">
                <v-chip color="grey" class="d-flex justify-center" label>
                  <div
                    class="d-flex align-center"
                    style="text-align: center; font-size: 11px !important; letter-spacing: 0.5px; color: azure"
                  >
                    <v-icon color="" small class="mr-2">
                      {{ icons.mdiCalendarClock }}
                    </v-icon>
                    <div class="mr-1">
                      {{ pinia.viewDialogData.updated_at | moment('DD.MM.YYYY ') }}
                    </div>

                    <span> - {{ pinia.viewDialogData.updated_at | moment('HH:mm') }}</span>
                  </div>
                </v-chip>
              </v-list-item-action>
            </v-list-item>
            <v-divider />

            <v-list-item class="px-3 px-md-6">
              <v-list-item-content>
                <v-list-item-subtitle>Kayıt Tarihi</v-list-item-subtitle>
                <v-list-item-title v-if="!$vuetify.breakpoint.smAndUp">{{
                  pinia.viewDialogData.created_at | moment('DD.MM.YYYY - HH:mm')
                }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action v-if="$vuetify.breakpoint.smAndUp">
                <v-chip color="grey" class="d-flex justify-center" label>
                  <div
                    class="d-flex align-center"
                    style="text-align: center; font-size: 11px !important; letter-spacing: 0.5px; color: azure"
                  >
                    <v-icon color="" small class="mr-2">
                      {{ icons.mdiCalendarClock }}
                    </v-icon>
                    <div class="mr-1">
                      {{ pinia.viewDialogData.created_at | moment('DD.MM.YYYY ') }}
                    </div>

                    <span> - {{ pinia.viewDialogData.created_at | moment('HH:mm') }}</span>
                  </div>
                </v-chip>
              </v-list-item-action>
            </v-list-item>
            <v-divider />

            <v-list-item class="px-3 px-md-6">
              <v-list-item-content>
                <v-list-item-subtitle>Çek Adet</v-list-item-subtitle>
                <v-list-item-title v-if="!$vuetify.breakpoint.smAndUp"
                  >{{ pinia.viewDialogData.checksPic }} Adet</v-list-item-title
                >
              </v-list-item-content>
              <v-list-item-action v-if="$vuetify.breakpoint.smAndUp">
                <v-chip color="#9391a2" outlined class="d-flex justify-center" label>
                  <div class="text-capitalize" style="text-align: center; font-size: 11px !important">
                    {{ pinia.viewDialogData.checksPic }} Adet
                  </div>
                </v-chip>
              </v-list-item-action>
            </v-list-item>
            <v-divider />

            <v-list-item class="px-3 px-md-6">
              <v-list-item-content>
                <v-list-item-subtitle>Çek Toplam Tutar</v-list-item-subtitle>
                <v-list-item-title v-if="!$vuetify.breakpoint.smAndUp"
                  >{{ pinia.viewDialogData.checksTotal | currency }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action v-if="$vuetify.breakpoint.smAndUp">
                {{ pinia.viewDialogData.checksTotal | currency }}
              </v-list-item-action>
            </v-list-item>
            <v-divider />

            <v-list-item three-line class="px-3 px-md-6">
              <v-list-item-content>
                <v-list-item-subtitle>Açıklama</v-list-item-subtitle>
                <v-list-item-title>{{ pinia.viewDialogData.description }} </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-list>
            <v-subheader class="px-3 px-md-6 text-subtitle-1 text-md-h6 white--text">Faturalar</v-subheader>
            <template v-for="(item, index) in pinia.viewDialogData.invoices_details">
              <v-divider :key="`divider-${index}`" />
              <v-list-item two-line :key="`item-${index}`">
                <v-list-item-content class="ml-3">
                  <v-list-item-title> {{ item.amount | currency }} </v-list-item-title>
                  <v-list-item-subtitle> {{ item.invoice_date.slice(0, 10) }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action-text>
                  <v-chip label color="secondary">{{ item.salinvtype }} - {{ item.salinvnum }}</v-chip>
                </v-list-item-action-text>
              </v-list-item>
            </template>
          </v-list>

          <v-list>
            <v-subheader class="px-3 px-md-6 text-subtitle-1 text-md-h6 white--text">Çekler</v-subheader>
            <template v-for="(item, index) in pinia.viewDialogData.checks">
              <v-divider :key="`divider-${index}`" />
              <v-list-item two-line :key="`item-${index}`">
                <v-list-item-avatar tile :height="90" :width="90" color="white" class="rounded-lg">
                  <a v-if="item.front_image_path" :href="$baseUrlAPI + '' + item.front_image_path" target="_blank">
                    <v-avatar tile :height="90" :width="90">
                      <v-img :src="$baseUrlAPI + '' + item.front_image_path" class="rounded-lg" />
                    </v-avatar>
                  </a>
                  <v-card
                    @click="addSingleImage(item, 'front')"
                    color="error"
                    width="100%"
                    height="100%"
                    v-else
                    class="d-flex align-center justify-center"
                  >
                    <div>
                      <v-icon size="30" color="white"> {{ icons.mdiProgressUpload }}</v-icon>
                      <div class="mt-1 white--text text-caption font-weight-bold">ÖN YÜZ</div>
                    </div>
                  </v-card>
                </v-list-item-avatar>
                <v-list-item-avatar tile :height="90" :width="90" color="white" class="rounded-lg">
                  <a v-if="item.back_image_path" :href="$baseUrlAPI + '' + item.back_image_path" target="_blank">
                    <v-avatar tile :height="90" :width="90">
                      <v-img :src="$baseUrlAPI + '' + item.back_image_path" class="rounded-lg" />
                    </v-avatar>
                  </a>

                  <v-card
                    @click="addSingleImage(item, 'back')"
                    color="error"
                    width="100%"
                    height="100%"
                    v-else
                    class="d-flex align-center justify-center"
                  >
                    <div>
                      <v-icon size="30" color="white"> {{ icons.mdiProgressUpload }}</v-icon>
                      <div class="mt-1 white--text text-caption font-weight-bold">ARKA YÜZ</div>
                    </div>
                  </v-card>
                </v-list-item-avatar>

                <v-list-item-content class="ml-3">
                  <v-list-item-title> {{ item.total_price | currency }} </v-list-item-title>
                  <v-list-item-subtitle> {{ item.expiry_date.slice(0, 10) }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>

          <div class="text-center"></div>
        </v-card-text>
        <v-card-actions class="py-3" v-if="pinia.viewDialogData.manage_show_approve_button == 1">
          <v-row>
            <v-col cols="6">
              <v-btn
                @click="collectionStatusUpdate(pinia.viewDialogData.id, 'approve')"
                color="success"
                block
                class="text-capitalize"
                rounded
                x-large
              >
                Onayla</v-btn
              >
            </v-col>

            <v-col cols="6">
              <v-btn
                @click="collectionStatusUpdate(pinia.viewDialogData.id, 'cancel')"
                color="error"
                block
                class="text-capitalize"
                rounded
                x-large
              >
                Reddet</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
        <v-card-actions class="py-3" v-if="userData.role !== 'PLSYR' && pinia.viewDialogData.status == 8">
          <v-btn
            color="secondary"
            @click="saldeptCollectionCompleteStatus(pinia.viewDialogData.id)"
            class="text-capitalize"
            block
            rounded
            x-large
          >
            Çek Teslim Alındı</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <AddImage v-if="pinia.checkListSingleImageUpload.length > 0" />
  </div>
</template>

<script>
import { ref, onMounted } from 'vue-demi'

// Component

import AddImage from '../add/AddImage.vue'

// Pinia
import { useCollectionStore } from '../store'

// Utils
import { useScroll, useResizeObserver } from '@vueuse/core'
import { postData } from '@utils'
import { useToast } from 'vue-toastification/composition'

// Icons
import { mdiClose, mdiTrashCan, mdiCalendarClock, mdiProgressUpload, mdiDeleteOutline } from '@mdi/js'

export default {
  components: { AddImage },
  setup(props, { emit }) {
    // Pinia
    const pinia = useCollectionStore()

    const toast = useToast()
    const scrollableEl = ref(null)
    const userData = JSON.parse(localStorage.getItem('userData'))
    // Kaydırma çubuğu
    const { arrivedState } = useScroll(scrollableEl)
    const isScroll = ref(false)
    onMounted(() => {
      isScroll.value = scrollableEl.value?.scrollHeight === scrollableEl.value?.offsetHeight
    })

    // Ekran boyutu algılama
    useResizeObserver(scrollableEl, entries => {
      isScroll.value = scrollableEl.value?.scrollHeight === scrollableEl?.value.offsetHeight
    })

    const saldeptCollectionCompleteStatus = id => {
      postData({
        method: 'saldeptCollectionCompleteStatus',
        id,
      })
        .then(response => {
          // API dönüşündeki hatalara bakıyoruz
          if (response.error) {
            throw new Error(response.msg)
          }

          toast.success('İşlem Başarılı', {
            position: 'bottom-center',
          })
          emit('tableFetch')
        })
        .catch(e => {
          // API hataya girdi

          toast.error(e.message, {
            position: 'bottom-center',
          })
        })
        .finally(() => {
          // API işlemi bittikten sonra
        })
    }
    const loadingUpdate = ref(false)
    const collectionStatusUpdate = (id, moderate) => {
      loadingUpdate.value = true
      postData({
        method: 'collectionStatusUpdate',
        id,
        moderate,
      })
        .then(response => {
          // API dönüşündeki hatalara bakıyoruz
          if (response.error) {
            throw new Error(response.msg)
          }

          toast.success(response.msg, {
            position: 'bottom-center',
          })
          emit('tableFetch')
        })
        .catch(e => {
          // API hataya girdi

          toast.error(e.message, {
            position: 'bottom-center',
          })
        })
        .finally(() => {
          // API işlemi bittikten sonra
          loadingUpdate.value = true
        })
    }

    const addSingleImage = (item, location) => {
      if (location === 'front') {
        pinia.checkListSingleImageUpload.push({
          id: item.id,
          file: null,
          slug: 'front_image_path',
          name: 'Çek Ön Yüz',
          date: item.expiry_date,
          amount: item.total_price,
        })
      } else if (location === 'back') {
        pinia.checkListSingleImageUpload.push({
          id: item.id,
          file: null,
          slug: 'back_image_path',
          name: 'Çek Arka Yüz',
          date: item.expiry_date,
          amount: item.total_price,
        })
      }

      pinia.addImageDialog = true
    }

    return {
      userData,

      pinia,
      scrollableEl,
      isScroll,
      arrivedState,
      saldeptCollectionCompleteStatus,
      collectionStatusUpdate,
      loadingUpdate,
      addSingleImage,
      // icons
      icons: {
        mdiClose,
        mdiTrashCan,
        mdiCalendarClock,
        mdiProgressUpload,
        mdiDeleteOutline,
      },
    }
  },
}
</script>
