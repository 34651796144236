import { defineStore } from 'pinia'
import { postData, formData } from '@utils'
import { ref } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import { useRouter } from '@core/utils'

export const useCollectionStore = defineStore('collection', () => {
  const toast = useToast()
  const { router } = useRouter()

  const slide = ref(0)
  const loading = ref(false)
  const imageLoading = ref(false)
  const invoices = ref([])
  const checkList = ref([])
  const description = ref(null)
  const calculate = ref(null)
  const editedItem = ref({ total_price: null, expiry_date: null })
  const defaultItem = ref({ total_price: null, expiry_date: null })
  const headers = ref([
    {
      text: '#',
      value: 'index',
      align: 'center',
      width: 50,
    },
    {
      text: 'Çek Tutar',
      align: 'left min-width-150',
      value: 'total_price',
    },
    { text: 'Vade Tarih', value: 'expiry_date', align: 'right', width: 120 },
    { text: '', value: 'actions', align: 'right', width: 50 },
  ])

  const checkListSingleImageUpload = ref([])
  const checkListImageUpload = ref([])

  const addImageDialog = ref(false)

  const addItem = () => {
    if (editedItem.value.total_price && editedItem.value.expiry_date) {
      checkList.value.push(editedItem.value)
      editedItem.value = Object.assign({}, defaultItem.value)
    }
  }

  const deleteItem = item => {
    checkList.value.splice(checkList.value.indexOf(item), 1)
  }

  const emptyData = () => {
    invoices.value = []
    description.value = null
    calculate.value = null
    checkList.value = []
    slide.value = 0
  }

  // Ekle / Düzenle / Sil
  const viewDialogData = ref({})
  const viewDialogModel = ref(false)
  const viewDialogLoading = ref(false)
  const deleteLoading = ref(false)

  const viewDialogClose = () => {
    viewDialogModel.value = false
    viewDialogLoading.value = false
    deleteLoading.value = false
  }

  const imageUpload = item => {
    imageLoading.value = true

    const data = new FormData()
    data.append('method', 'uploadImagePlasiyerCheck')
    data.append('localid', item.id)
    data.append('file', item.file)
    data.append('realfilename', item.file.name)
    data.append('uploadid', item.slug)

    formData(data)
      .then(response => {
        if (response.error) {
          throw new Error(response.msg)
        }
        if (checkListSingleImageUpload.value.length > 0) {
          toast.success('Görsel yüklemeleri tamamlandı. Yönelendiriliyorsunuz', {
            position: 'bottom-center',
          })

          setTimeout(() => {
            location.reload()
          }, 300)
          return
        }
        if (checkListImageUpload.value.length * 2 === slide.value + 1) {
          addImageDialog.value = false
          checkListImageUpload.value = []
          toast.success('Görsel yüklemeleri tamamlandı. Yönelendiriliyorsunuz', {
            position: 'bottom-center',
          })
          router.push({ name: 'collection-list' })
        } else {
          toast.info('Görsel yüklendi, şimdi sıradakı görseli yükleyin', {
            position: 'bottom-center',
          })
          slide.value++
        }
      })
      .catch(e => {
        // API hataya girdi
        toast.error(e.message, {
          position: 'bottom-center',
        })
      })
      .finally(() => {
        // API işlemi bittikten sonra
        checkListSingleImageUpload.value = []
        imageLoading.value = false
      })
  }

  const save = () => {
    if (checkList.value.length === 0) {
      return
    }
    loading.value = true
    const data = {
      description: description.value,
      invoices: invoices.value,
      checks: checkList.value,
    }
    postData({
      method: 'compareCollectionCheck',
      body: JSON.stringify(data),
    })
      .then(response => {
        if (response.error) {
          throw new Error(response.msg)
        }
        checkListImageUpload.value = response.compareChecks
        addImageDialog.value = true
      })
      .catch(e => {
        // API hataya girdi
        toast.error(e.message, {
          position: 'bottom-center',
        })
      })
      .finally(() => {
        // API işlemi bittikten sonra
        emptyData()
        loading.value = false
      })
  }

  const calculation = () => {
    if (checkList.value.length === 0) {
      return
    }
    loading.value = true
    const data = {
      description: description.value,
      invoices: invoices.value,
      checks: checkList.value,
    }
    postData({
      method: 'calculateCollection',
      body: JSON.stringify(data),
    })
      .then(response => {
        if (response.error == 1) {
          throw new Error(response.msg)
        }
        calculate.value = response

        // checkListImageUpload.value = response.compareChecks
        // addImageDialog.value = true
      })
      .catch(e => {
        // API hataya girdi
        toast.error(e.message, {
          position: 'bottom-center',
        })
      })
      .finally(() => {
        // API işlemi bittikten sonra
        //emptyData()
        loading.value = false
      })
  }

  const sendCheck = () => {
    if (checkList.value.length === 0) {
      return
    }
    loading.value = true
    const data = {
      description: description.value,
      invoices: invoices.value,
      checks: checkList.value,
    }
    postData({
      method: 'compareCollectionCheck',
      body: JSON.stringify(data),
    })
      .then(response => {
        if (response.error == 1) {
          throw new Error(response.msg)
        }

        checkListImageUpload.value = response.compareChecks
        addImageDialog.value = true
      })
      .catch(e => {
        // API hataya girdi
        toast.error(e.message, {
          position: 'bottom-center',
        })
      })
      .finally(() => {
        // API işlemi bittikten sonra
        emptyData()
        loading.value = false
      })
  }

  const statusList = [
    { text: 'Bekliyor', value: '0', type: 'primary' },
    { text: 'Hesaplandı', value: '1', type: 'primary' },
    { text: 'Plasiyer Onayladı', value: '2', type: 'info' },
    { text: 'Görsel Bekleniyor', value: '3', type: 'error' },
    { text: 'Yönetici Onayı Bekleniyor', value: '4', type: 'warning' },
    { text: 'Yönetici Onayladı', value: '5', type: 'info' },
    { text: 'Yönetici Reddetti', value: '6', type: 'error' },
    { text: 'Finans Onayı Bekleniyor', value: '7', type: 'warning' },
    { text: 'Finans Onayladı', value: '8', type: 'info' },
    { text: 'Finans Reddetti', value: '9', type: 'error' },
    { text: 'Süreç tamamlandı', value: '10', type: 'success' },
  ]

  return {
    statusList,
    sendCheck,
    emptyData,
    viewDialogData,
    viewDialogModel,
    viewDialogLoading,
    deleteLoading,
    viewDialogClose,

    calculate,
    invoices,
    loading,
    description,
    checkList,
    checkListImageUpload,
    checkListSingleImageUpload,
    imageUpload,
    slide,
    editedItem,
    headers,
    addItem,
    deleteItem,
    save,
    calculation,
    imageLoading,
    addImageDialog,
  }
})
